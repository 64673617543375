@import '../../scss/base/variables';
@import '../../scss/base/mixins';

.ant-carousel{

    .slick-slider {

        .slick-arrow {
            @include animate(all);
            margin-top: -25px;
            width: 50px;
            height: 50px;
            font-size: $h1-font-size;
            color: $black;
            z-index: 2;
            border-radius: 50%;
            background-color: rgba($white, .5);

            &:focus,
            &:hover {
                background-color: rgba($white, .8);
                color: $black;
            }

            &:focus{
                background-color: rgba($white, .5);
            }


            &:before {
                display: none;
            }

            &.slick-disabled{
                transform: scale(0);
            }

            &.slick-next{
                right: -70px;
            }

            &.slick-prev{
                left: -70px;
            }

        }

    }

}


