@import '../../../scss/base/variables';

.jitsi-call-modal{
  min-width: auto!important;
  width: 800px!important;
  height: 500px;

  .ant-modal-close{
    top: -45px;
    right: -45px;
    color: $white;
  }

  .ant-modal-content{
    height: 100%;
    width: 100%;
  }

  .ant-modal-body{
    padding: 0;
    height: inherit;
    width: inherit;

    >iframe{
      display: block;
      height: inherit;
      width: inherit;
      border: 0
    }
  }
}