$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';
@import 'vendors/normalize';
// @import 'vendors/fonts';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';


body {
  @include media('<desktop') {
    font-size: 14px;
    line-height: 1.4;
  }
}

#root {
  position: relative;
  width: 100%;
  overflow: hidden;
}

h1 {

  @include media('<desktop') {
    font-size: 25px;
    line-height: 1.2;
  }
}

img {
  object-fit: contain;
}

.d-hide {
  display: none !important;
}

.d-md-hide {
  @include media('>=tablet') {
    display: none !important;
  }
}


.d-lg-hide {
  @include media('>=desktop') {
    display: none !important;
  }
}

.d-show {
  display: inherit !important;
}

.d-md-show {
  @include media('>=tablet') {
    display: inherit !important;
  }
}


.d-lg-show {
  @include media('>=desktop') {
    display: inherit !important;
  }
}

.page {
  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #292929;
    z-index: -1;
  }

  &.dashbord-page:before{
    // background-image: url("../assets/wall03.jpg");
    background-image: radial-gradient( circle farthest-corner at 50.4% 50.5%,  rgba(251,32,86,1) 0%, rgba(135,2,35,1) 90% );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  #brand-box {
    > *{
      outline: none;
    }
  }

  .element {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 180px;
    text-decoration: none !important;
    font-family: Helvetica, sans-serif;
    text-align: center;
    line-height: normal;
    padding: 30px;
    box-sizing: border-box;
    pointer-events: auto !important;

    &::before {
      @include animate(all);
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      border: 2px solid #FFFFFF;
      background-color: rgba(255,255,255,0.9);
      box-shadow: inset 0 0 10px 0 rgba(#fbb7c5 , 0.2);
      border-radius: 4px;
      z-index: -1;
    }

    &:hover {
      &::before {
        transform: scale(1.15);
        box-shadow: inset 0 0 10px 0 rgba(#fbb7c5 , 0.99);
      }

      .logo {
        transform: scale(1.15);
      }
    }

    .number {
      font-size: 12px;
      color: white;
    }

    .logo {
      @include animate(all);
      display: inline-block;
      height: 100px;
      //margin-bottom: 15px;
    }

    .details {
      @include animate(all);
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      bottom: 15px;
      left: 0;
      right: 0;
      font-size: 14px;
      color: $black;
    }
  }

  &.company-page {

    &.panorama{
      .content-holder{
        padding: 0;
      }
    }

    .contact-person{
      position: absolute;
      width: 200px;
      z-index: 1;
      right: 12%;
      bottom: -110px;
      display: none;

      @include media('>=tablet') {
        display: block;
      }

      > button{
        outline: none;
        border: none;
        background-color: transparent;
        text-align: left;

        img{
          max-width: 160px;
        }
      }

      p{
        margin: 0;
      }

      h4{
        font-size: 16px;
        margin: 10px 0 0;
        font-weight: normal;
        color: #c8c8c8;
      }

      h5{
        font-weight: normal;
        font-size: 13px;
        margin: 0;
        text-transform: uppercase;
      }

    }

    .content-holder{
      padding-bottom: 100px;
    }

    .container{
      .ant-carousel{
        margin-top: 40px;
      }
    }


    .holder-iframe {
      max-width: 700px;
      padding: 15px 0 30px;
      margin: 0 auto;

      @include media('>=tablet') {
        padding: 40px 0 100px;
      }
    }
  }

  &.dashbord-page {
      min-height: 100vh;
      // @include media('>=desktop') {
    //   height: 100vh;
    //   overflow: hidden;
    // }

    #switch-location {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;


      button {
        @include animate(all);
        color: rgba(#c8c8c8, 0.75);
        background: transparent;
        outline: 1px solid rgba(#c8c8c8, 0.75);
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.75);

        &#table {
          @include media('<desktop') {
            display: none;
          }
        }

        &:hover {
          background-color: rgba(#c8c8c8, 0.1);
          box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.5);
        }

        &:active {
          color: #FFF;
          background-color: rgba(#c8c8c8, 0.35);
          box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.products{
   .intro {
     margin-bottom: 80px;

     .box-img {
       height: 245px !important;

       > * {
         height: 100%;
       }
     }


   }

    h5{
      color: #c8c8c8;
      font-size: 15px;
      font-weight: normal;
    }

  .intro-block {
    padding: 0 20px;
    display: flex;
    > *{
      padding: 0 20px;

      &:first-child {
        width: 60%;
      }
      &:last-child {
        width: 40%;
      }
    }

    .slider-wrap{
      iframe,  .box-img{
        height: 400px;
        display: block;
      }

      h5{
        margin-bottom: 10px;
      }
    }
  }
}

.product-list{
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  .product{

    width: 33%;
    height: 300px;
    padding: 0 10px;

    > iframe {
      width: 100%;
      height: 100%;
      border: none;
      display: block;
    }

    > .blur-bg-box{
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    h5{
      margin-top: 10px;
    }
  }
}

.layout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: $white;
  background-size: contain;
  padding-right: 55px;

  @include media('>=desktop') {
    padding-right: 95px;
  }


  .sidebar {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 55px;
    text-align: center;
    border-left: 1px solid rgba($white, .2);
    padding: 65px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media('>=desktop') {
      width: 95px;
      padding: 80px 0 15px;
    }
  }

  .content-holder {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    .box-img {
      position: relative;
      text-align: center;
      overflow: hidden;
      height: 200px;

      @include media('>=desktop') {
        height: 500px;
      }

      img {
        position: relative;
        z-index: 1;
      }

      .blur-bg-box {
        content: "";
        position: absolute;
        // filter: blur(7px) contrast(125%) brightness(70%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
      }
    }
  }

  .intro-slider{

    iframe{

      height: 600px;
      width: 100%;
      border: none;
      display: block;
    }

    .box-img{
      height: 600px;
    }

    .slick-dots{
      margin-bottom: 0;
      background-color: rgba($white, 0.3);
      padding: 5px;
      border-radius: 10px;

      &.slick-dots-bottom{
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
        margin: auto;

        > li{
          margin-right: 5px;
          margin-left: 5px;

        }
      }

      li, button{
        width: 12px !important;
        height: 12px !important;
        border-radius: 50%;

      }

      button{
        background-color: #c8c8c8 !important;

      }

    }
  }
}

.intro {
  position: relative;
  margin-bottom: 140px;

  @include media('>=desktop') {
    margin-bottom: 160px;
  }

  .element {
    position: absolute;
    left: 200px;
    bottom: -80px;
    z-index: 1;

    @include media('<desktop') {
      left: 10px;
      width: 120px;
      height: 120px;
      padding: 20px 10px 20px;
      bottom: -60px;
    }

    .logo {
      height: 45px;

      @include media('>=desktop') {
        height: 90px;
      }
    }

    &:hover {
      &::before {
        transform: none;
      }

      .logo {
        transform: none;
      }
    }
  }
}

.navbar {
  @extend %listreset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 25px;

  @include media('>=desktop') {
    font-size: 30px;
  }

  >li {
    position: relative;
    width: 100%;

    + li{
      margin-top: 10px;

      @include media('>=tablet') {
        margin-top: 20px;
      }
    }

    >a, >button:not(.sub-nav-but) {
      @include animate(opacity);
      color: $white;

      &:hover {
        opacity: .5;
      }
    }

    >button{
      background-color: transparent;
      border: none;
      outline: none;
      width: inherit;

      &.sub-nav-but:hover {
        cursor: default;

        + .sub-nav{
          width: 150px;
        }
      }
    }
  }

  .sub-nav{
    @extend %listreset;
    @include animate(all);
    display: flex;
    position: absolute;
    right: 94px;
    top: 0;
    overflow: hidden;
    background-color: $gray;
    width: 0;

    a{
      padding: 10px;
      color: $white;
    }

    &:hover{
      width: 150px;
    }
  }
}

.container {
  width: 100%;
  max-width: 960px;
  padding: 0 20px;
  margin: 0 auto;
}

.btn-modal-search {
  position: fixed;
  right: 0;
  top: 20px;
  z-index: 1;
  background: transparent;
  border: none !important;
  padding: 4px 13px;

  @include media('>=desktop') {
    right: 16px;
    top: 20px;
    padding: 4px 15px;
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    border: none !important;
  }

  .anticon {
    &.anticon-search {
      transition: all .3s;
      opacity: 1;
      color: #c8c8c8;

      &:hover {
        opacity: .5;
      }

      svg {
        font-size: 26px;

        @include media('>=desktop') {
          font-size: 30px;
        }
      }
    }
  }
}

.embed-responsive {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;

  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.modal-complete-seaech {
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin-top: -250px;

  @include media('>=tablet') {
    margin-top: -150px;
  }

  .ant-modal-header {
    background: rgba($white, .1);

    .ant-modal-title {
      color: #63656a;
    }
  }

  .ant-modal-content {
    background: rgba($white, .8);

    .ant-modal-close {
      .anticon {
        color: #63656a;
      }
    }
  }

  .ant-modal-body {
    padding: 15px 0 !important;
  }
}

.complete-search {
  position: relative;
  width: 100%;
  font-size: 14px;
  margin: 0 auto;

  .ant-input-wrapper {
    padding: 0 15px;
  }

  .ant-input-search {

    .ant-input {
      text-transform: capitalize;
      background-color: #ededed;
      border-color: transparent;
      border-radius: 20px;
      padding: 5px 15px 5px 40px;
      margin: 0 auto;

      &:hover,
      &:focus {
        border-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;

      .ant-btn {
        padding-left: 27px;
        border: none;
        background-color: transparent;

        &:hover,
        &:focus {
          border-color: transparent;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.complete-search-dropdown {
  box-shadow: none;
  background: rgba($white, .8);

  .rc-virtual-list-scrollbar-thumb {
    background: #c8c8c8 !important;
  }

  .ant-select-item {
    text-transform: capitalize;
    line-height: 16px;
    min-height: auto;

    &.ant-select-item-option {
      &:hover {
        background: #c8c8c8;
      }
    }

    &.ant-select-item-option-active {
      &:not(.ant-select-item-option-disabled) {
        background: #c8c8c8;
      }
    }

    a {
      color: $black;
      font-size: 16px;
    }
  }

  .rc-virtual-list {
    padding-top: 25px;
  }
}

.back-home {
  @include animate(opacity);
  color: $white;
  font-size: 24px;

  @include media('>=desktop') {
    font-size: 27px;
  }

  &:hover {
    color: $white;
    opacity: .6;
  }
}

.panorama-frame{
  height: 100vh;
  width: 100%;
  border: none;
  display: block;
}
